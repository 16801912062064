import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingOverlay } from '@mantine/core';
import AuthenticatedApp from 'views/AuthenticatedApp';
import UnAuthenticatedApp from 'views/UnAuthenticatedApp';

const App = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) {
    return <LoadingOverlay visible />;
  }
  return isAuthenticated ? <AuthenticatedApp /> : <UnAuthenticatedApp />;
};

export default App;
