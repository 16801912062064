// IMPORTANT:
// this is a stripped down version of @mantine/core Select component, which we couldn't use directly
// for inifinite select, because it doesn't allow programmatical closing of the custom dropdown ¯\_(ツ)_/¯

import React, { useState, useEffect, useRef } from 'react';
import { useDebouncedValue, useDidUpdate, useUncontrolled } from '@mantine/hooks';
import { getDefaultZIndex } from '@mantine/styles';
import { Input, Popover, SelectProps, useInputProps, rem } from '@mantine/core';

// @ts-ignore
import { SelectItemsProps } from '@mantine/core/esm/Select/SelectItems/SelectItems';
// @ts-ignore
import { DefaultItem } from '@mantine/core/esm/Select/DefaultItem/DefaultItem';
// @ts-ignore
import { SelectPopover } from '@mantine/core/esm/Select/SelectPopover/SelectPopover';
// @ts-ignore
import { getSelectRightSectionProps } from '@mantine/core/esm/Select/SelectRightSection/get-select-right-section-props';

import useStyles from './InfiniteSelect.styles';
import { InfiniteListItem } from 'components/InfiniteList/InfiniteList';
import { identity } from 'helpers/miscUtils';

export type InfiniteDropdownProps = SelectItemsProps & {
  query?: string;
};

const DUMMY_OBJ = {};

interface InfiniteSelectProps<TValue = string>
  extends Omit<SelectProps, 'data' | 'dropdownComponent' | 'onChange' | 'value' | 'defaultValue'> {
  value?: InfiniteListItem<TValue> | null;
  defaultValue?: InfiniteListItem<TValue> | null;
  onChange?: (value: InfiniteListItem<TValue> | null) => void;
  dropdownComponent?: React.FC<InfiniteDropdownProps>;
  itemToLabel?: (item: TValue) => string;
  itemToValue?: (item: TValue) => string;
}

const defaultProps = {
  required: false,
  size: 'sm',
  shadow: 'sm',
  itemComponent: DefaultItem,
  transitionProps: { transition: 'fade', duration: 0 },
  initiallyOpened: false,
  maxDropdownHeight: 220,
  searchable: false,
  clearable: false,
  limit: Infinity,
  disabled: false,
  selectOnBlur: false,
  switchDirectionOnFlip: false,
  filterDataOnExactSearchMatch: false,
  zIndex: getDefaultZIndex('popover'),
  positionDependencies: [],
  dropdownPosition: 'flip',
};

export function InfiniteSelect<TValue = string>(props: InfiniteSelectProps<TValue>) {
  const {
    inputProps,
    wrapperProps,
    shadow,
    value,
    defaultValue,
    onChange,
    itemComponent,
    onBlur,
    onFocus,
    initiallyOpened,
    unstyled,
    classNames,
    styles,
    maxDropdownHeight,
    searchable,
    clearable,
    nothingFound,
    disabled,
    onSearchChange,
    searchValue,
    rightSection,
    dropdownComponent: Dropdown,
    onDropdownClose,
    onDropdownOpen,
    withinPortal,
    portalProps,
    switchDirectionOnFlip,
    zIndex,
    name,
    dropdownPosition,
    allowDeselect,
    placeholder,
    form,
    positionDependencies = [],
    readOnly = false,
    clearButtonProps,
    hoverOnSearchChange,
    itemToLabel = identity,
    radius,
  } = useInputProps('Select', defaultProps as InfiniteSelectProps<TValue>, props);

  const itemToValue = props.itemToValue ?? itemToLabel;
  const { classes, cx, theme } = useStyles();
  const [dropdownOpened, _setDropdownOpened] = useState(initiallyOpened);
  const [hovered, setHovered] = useState(-1);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const itemsRefs = useRef<Record<string, HTMLDivElement>>({});

  const isDeselectable = allowDeselect === undefined ? clearable : allowDeselect;

  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    finalValue: null,
    onChange,
  });

  const [inputValue, setInputValue] = useUncontrolled({
    value: searchValue,
    defaultValue: _value?.label || '',
    finalValue: undefined,
    onChange: onSearchChange,
  });
  const [debouncedInputValue] = useDebouncedValue(inputValue, 500);

  const setDropdownOpened = (opened: boolean) => {
    if (dropdownOpened !== opened) {
      _setDropdownOpened(opened);
      const handler = opened ? onDropdownOpen : onDropdownClose;
      typeof handler === 'function' && handler();
    }
  };

  const handleSearchChange = (val: string) => {
    setInputValue(val);
    if (searchable && typeof onSearchChange === 'function') {
      onSearchChange(val);
    }
  };

  const handleClear = () => {
    if (!readOnly) {
      handleChange(null);
      handleSearchChange('');
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    if (_value && (!searchable || !dropdownOpened)) {
      handleSearchChange(itemToLabel(_value?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);

  const handleItemSelect = (item: InfiniteListItem<TValue>) => {
    if (!readOnly) {
      if (isDeselectable && value?.value && itemToValue(value.value) === itemToValue(item.value)) {
        handleChange(null);
        setDropdownOpened(false);
      } else {
        handleChange(item);
        setHovered(-1);
        setDropdownOpened(false);
        inputRef.current?.focus();
      }
    }
  };

  useDidUpdate(() => {
    if (hoverOnSearchChange) {
      setHovered(0);
    } else {
      setHovered(-1);
    }
  }, [hoverOnSearchChange]);

  const shouldShowDropdown = !readOnly && dropdownOpened;

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    typeof onBlur === 'function' && onBlur(event);
    setDropdownOpened(false);
  };

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    typeof onFocus === 'function' && onFocus(event);
    if (searchable) {
      setDropdownOpened(true);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      handleSearchChange(event.currentTarget.value);

      if (clearable && event.currentTarget.value === '') {
        handleChange(null);
      }

      setHovered(-1);
      setDropdownOpened(true);
    }
  };

  const handleInputClick = () => {
    if (!readOnly) {
      setDropdownOpened(!dropdownOpened);
    }
  };

  const rightSectionProps = getSelectRightSectionProps({
    theme,
    styles,
    size: inputProps.size,
    shouldClear: !!(clearable && value),
    onClear: handleClear,
    error: wrapperProps.error,
    clearButtonProps,
    disabled,
    readOnly,
  });

  return (
    <Input.Wrapper {...wrapperProps} __staticSelector="Select">
      <SelectPopover
        opened={shouldShowDropdown}
        transitionProps={DUMMY_OBJ}
        shadow={shadow}
        withinPortal={withinPortal}
        portalProps={portalProps}
        __staticSelector="Select"
        switchDirectionOnFlip={switchDirectionOnFlip}
        zIndex={zIndex}
        dropdownPosition={dropdownPosition}
        positionDependencies={[...positionDependencies, inputValue]}
        classNames={classNames}
        styles={styles}
        unstyled={unstyled}
        variant={inputProps.variant}>
        <SelectPopover.Target>
          <div onMouseLeave={() => setHovered(-1)} tabIndex={-1}>
            <input
              type="hidden"
              name={name}
              value={value?.value ? itemToValue(value.value) : ''}
              form={form}
              disabled={disabled}
            />

            <Input<'input'>
              autoComplete="off"
              type="search"
              {...inputProps}
              icon={props.icon}
              __staticSelector="Select"
              value={inputValue}
              placeholder={placeholder}
              radius={radius}
              onChange={handleInputChange}
              onMouseDown={handleInputClick}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              readOnly={!searchable || readOnly}
              disabled={disabled}
              data-mantine-stop-propagation={shouldShowDropdown}
              classNames={{
                ...classNames,
                input: cx({ [classes.input]: !searchable }, (classNames as any)?.input),
              }}
              {...rightSectionProps}
              rightSection={
                <>
                  {rightSectionProps.rightSection}
                  {rightSection}
                </>
              }
            />
          </div>
        </SelectPopover.Target>

        <Popover.Dropdown p={0} onMouseDown={(event) => event.preventDefault()}>
          <div style={{ maxHeight: rem(maxDropdownHeight), display: 'flex', width: '100%' }}>
            {/* @ts-ignore */}
            <Dropdown
              key={debouncedInputValue ?? '__all'}
              hovered={hovered}
              classNames={classNames}
              styles={styles}
              uuid={inputProps.id}
              __staticSelector="Select"
              onItemHover={setHovered}
              onItemSelect={handleItemSelect}
              itemsRefs={itemsRefs}
              // @ts-ignore
              itemComponent={itemComponent}
              size={inputProps.size}
              nothingFound={nothingFound}
              unstyled={unstyled}
              variant={inputProps.variant}
              query={debouncedInputValue !== value?.label ? debouncedInputValue : ''}
            />
          </div>
        </Popover.Dropdown>
      </SelectPopover>
    </Input.Wrapper>
  );
}
