import api from 'api/client';
import { queryResponseValidator } from 'helpers/queryUtils';
import { ChatSwitchCoachParams, ScheduleMessageParams, User } from 'types';
import {
  SearchUsersRequest,
  SearchUsersResponse,
  searchUsersResponseSchema,
  userSearchRequestSchema,
} from './users';
import { z } from 'zod';

interface AuthorizeChatResponse {
  status: string;
  streamToken: string;
}
export interface SwitchCoachResponse {
  status: string;
  coach: User;
  channelId: string;
}

export const scheduledMessageSchema = z.object({
  id: z.string(),
  userId: z.string(),
  userFirstName: z.string(),
  userLastName: z.string(),
  userTimezone: z.string(),
  userChannelId: z.string(),
  senderId: z.string(),
  datetime: z.string(),
  text: z.string(),
  status: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  groupId: z.string(),
  sentAt: z.string().optional(),
  attachments: z.string().optional(),
  internalNote: z.string().optional(),
});

export type ScheduledMessageTask = z.infer<typeof scheduledMessageSchema>;

export const authorizeChat = async () => {
  const { streamToken } = await api.post(`chat/authorize`).json<AuthorizeChatResponse>();
  return streamToken;
};

export const switchCoach = async (body: ChatSwitchCoachParams) => {
  return await api.put(`chat/switch_coach`, { json: body }).json<SwitchCoachResponse>();
};

export type FindCoachResponseBody = {
  status: string;
  coach: User;
  channelId: string;
};

export const findCoach = async () => {
  const { coach } = await api.post(`chat/find_coach`).json<FindCoachResponseBody>();
  return coach;
};

export const hideUser = async (userId: string) => {
  const response = await api.put(`chat/${userId}/hide`).json();
  return response;
};

export const createChannel = async (userId: string) => {
  const response = await api
    .post(`chat/create_channel`, { json: { userId } })
    .json<{ channelId: string }>();
  return response;
};

export const chatUsers = async (body?: SearchUsersRequest) => {
  return api
    .post(`chat/users`, { json: queryResponseValidator(userSearchRequestSchema)(body) ?? {} })
    .json()
    .then(queryResponseValidator<SearchUsersResponse>(searchUsersResponseSchema));
};

export const scheduleMessage = async (data: ScheduleMessageParams) => {
  return api.post('scheduledMessages/create', { json: data }).json();
};

export const editScheduledMessage = async (data: ScheduleMessageParams) => {
  return api.put('scheduledMessages/edit', { json: data }).json();
};

export const getScheduledMessages = async () => {
  return api.get('scheduledMessages').json<ScheduledMessageTask[]>();
};

export const deleteScheduledMessage = async (item: ScheduledMessageTask) => {
  if (!item.id) {
    throw new Error(`'id' is missing on the news item`);
  }
  return api.delete(`scheduledMessages/${item.id}`).json();
};
