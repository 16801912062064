import { Menu, Text } from '@mantine/core';
import { InlineIcon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserData } from 'types';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';

interface UserMenuProps {
  user: UserData['me'];
  onLogout: () => void;
}

const UserMenu = ({ user, onLogout }: UserMenuProps) => {
  const { t } = useTranslation('core');
  return (
    <Menu>
      <Menu.Target>
        <UserAvatar user={user} />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Text color={'dimmed'}>{user?.email}</Text>
        </Menu.Label>
        <Menu.Divider />
        <Menu.Item
          component={Link}
          to="/settings/user/profile"
          icon={<InlineIcon icon={'ri:settings-3-line'} />}>
          {t('core:settings')}
        </Menu.Item>
        <Menu.Item onClick={onLogout} icon={<InlineIcon icon={'ri:logout-circle-r-line'} />}>
          {t('core:logout')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserMenu;
