import api from 'api/client';
import { queryResponseValidator, strToBoolean } from 'helpers/queryUtils';
import { SearchUsersParams, TrialPeriodParams } from 'types';

import { STATUS } from './types';
import { EnumValues, z } from 'zod';
import { isBoolean, isNumber, isString } from 'lodash';
import { isNumeric } from 'helpers/miscUtils';

export enum PaymentMethod {
  AppStore = 'AppStore',
  Stripe = 'Stripe',
}

const paymentMethodSchema = z.enum([PaymentMethod.AppStore, PaymentMethod.Stripe]);

export enum SubscriptionStatus {
  FREE = 'free',
  PREMIUM = 'premium',
  CANCELED = 'canceled',
  PAUSED = 'paused',
  TRIAL = 'trial',
}

export const intSchema = z.preprocess((val) => Number(val), z.number().int().nonnegative());

export const numberSchema = z.preprocess(
  (val) => (isNumeric(val) ? Number(val) : val),
  z.number().optional(),
);

export const booleanSchema = z.preprocess(
  (val) => (isBoolean(val) || isString(val) ? strToBoolean(val) : undefined),
  z.boolean().optional(),
);

export const dateSchema = z.preprocess(
  (val) => (isNumber(val) || isString(val) ? new Date(val) : undefined),
  z.date().or(z.null()).optional(),
);

const subscriptionStatusSchema = z.enum([
  SubscriptionStatus.FREE,
  SubscriptionStatus.PREMIUM,
  SubscriptionStatus.CANCELED,
  SubscriptionStatus.PAUSED,
  SubscriptionStatus.TRIAL,
]);

export enum Role {
  DEV = 'developer',
  ADMIN = 'admin',
  COACH = 'coach',
  COACH_MANAGER = 'coach_manager',
  THERAPIST = 'therapist',
  PRODUCT = 'product',
  USER = 'user',
}

// IMPORTANT!!!
// do not edit manually, copy/paste from the BE: src/types/Permission
export enum Permission {
  // Developer permissions
  DEV_ALL = 'developer:all',

  // Coaching permissions
  // - view own users
  // - view user actions
  // - read chat messages
  COACHING_READ = 'coaching:read',
  // - create and change action items
  // - write chat messages
  // - upload meeting transcript
  // - toggle show/no-show for a meeting
  COACHING_WRITE = 'coaching:write',

  // Moderation permissions
  // - view all users
  // - view user action triggers
  MODERATION_READ = 'moderation:read',
  // - set manual trial on a user
  // - update Coach and Therapist profiles
  // - toggle rotation on a Coach
  // - switch Coach for a user
  // - suspend a user
  // - delete a user
  // - create, update and delete user action triggers
  MODERATION_WRITE = 'moderation:write',

  // Content permissions
  // - view scheduled notifications
  // - view all company codes
  CONTENT_READ = 'content:read',
  // - manage cbt programs
  // - manage notifications
  // - manage therapy courses
  // - manage news
  CONTENT_WRITE = 'content:write',

  // Therapy permissions
  THERAPY_ALL = 'therapy:all',

  // Administration permissions
  // - view all users
  // - switch roles
  ADMINISTRATION_ALL = 'administration:all',
  ADMINISTRATION_IMPERSONATION = 'administration:impersonation',
}

export const roleSchema = z.enum(Object.values(Role) as EnumValues);
export const permissionSchema = z.enum(Object.values(Permission) as EnumValues);

const prismaQuerySchema = z.object({
  OR: z.any().optional(),
  AND: z.any().optional(),
  isNot: z.any().optional(),
  is: z.any().optional(),
  in: z.array(z.any()).optional(),
  gte: z.any().or(z.null()).optional(),
  lte: z.any().or(z.null()).optional(),
  some: z.any().or(z.null()).optional(),
});

const licenseSchema = z.object({
  description: z.string(),
  state: z.string(),
});

const coachSchema = z.object({
  tags: z.string().optional(),
  introCallUrl: z.string().optional(),
  weekendWelcomeVideoUrl: z.string().optional(),
  isProd: z.boolean().optional(),
  richTextDescription: z.string().optional(),
  oneLiner: z.string().optional(),
  calendarUrl: z.string().optional(),
  license: z.array(licenseSchema).optional(),
});

export const userSchema = z
  .object({
    nickname: z.string().optional(),
    firstName: z.string().or(z.null()).optional(),
    lastName: z.string().or(z.null()).optional(),
    email: z.string(),
    userId: z.string(),
    isCoach: z.boolean(),
    isAdmin: z.boolean().optional(),
    isDev: z.boolean().optional(),
    channelId: z.string().or(z.null()).optional(),
    coachId: z.string().or(z.null()).optional(),
    avatarUrl: z.string().or(z.null()).optional(),
    description: z.string().optional(),
    subscriptionStatus: subscriptionStatusSchema,
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    subscriptionLastPaymentDate: z.string().or(z.null()).optional(),
    isDeleted: z.boolean().optional(),
    trialEndDate: z.string().or(z.null()).optional(),
    notificationsTurnedOn: z.boolean().optional(), // @deprecated
    isNotificationsAllowed: z.boolean().optional(),
    premiumSinceDate: z.string().or(z.null()).optional(),
    canceledSinceDate: z.string().or(z.null()).optional(),
    appVersion: z.string().or(z.null()).optional(),
    timezone: z.string().or(z.null()).optional(),
    coach: coachSchema.optional(),
  })
  .merge(coachSchema);

export const followupTypeSchema = z.enum(['trial', 'action', 'meeting', 'followup', 'disengaged']);

const followupBucketSchema = z.object({
  isFollowupRequired: z.boolean().optional(),
  isHavingAction: z.boolean().optional(),
  isMeetingCoachToday: z.boolean().optional(),
  isAwaitingResponse: z.boolean().optional(),
  isDisengaged: z.boolean().optional(),
});

const userRoleSchema = z.object({
  role: roleSchema.or(z.any()).optional(),
});

export const therapySchema = z.object({
  userId: z.string(),
  courseId: z.string(),
  therapistId: z.string(),
  channelId: z.string().optional(),
  status: z.string(),
  createdAt: dateSchema,
  updatedAt: dateSchema,
});

export const userViewSchema = z
  .object({
    gender: z.string().or(z.null()).optional(),
    dateOfBirth: dateSchema,
    lastSeenDate: dateSchema,
    lastCallDate: dateSchema,
    firstCallDate: dateSchema,
    activitiesAmount: z.number().optional(),
    callsScheduledAmount: z.number().optional(),
    appVersion: z.string().or(z.null()).optional(),
    platform: z.string().or(z.null()).optional(),
    device: z.string().nullable().optional(),
    ipAddress: z.string().nullable().optional(),
    paymentMethod: paymentMethodSchema.nullable().optional(),
    isAlpha: z.boolean().optional(),
    isBillingIssue: z.boolean().optional(),
    clientCompany: z.string().nullable().optional(),
    followupBucket: followupBucketSchema.or(z.null()).optional(),
    userRole: userRoleSchema.or(z.null()).optional(),
    role: roleSchema.optional(),
    permissions: z.array(permissionSchema).optional(),
    therapies: z.array(therapySchema).optional(),
  })
  .merge(userSchema);

const querySchema = z
  .object({
    subscriptionStatus: subscriptionStatusSchema.optional(),
    coachId: z.string().optional(),
    isCoach: booleanSchema,
    platform: z.string().optional(),
    paymentMethod: paymentMethodSchema.optional(),
    lastSeenDate: prismaQuerySchema.optional(),
    isNotificationsAllowed: booleanSchema.optional(),
    clientCompany: z.string().optional(),
    followupBucket: followupBucketSchema.merge(prismaQuerySchema).or(z.null()).optional(),
    userRole: userRoleSchema.merge(prismaQuerySchema).or(z.null()).optional(),
    therapies: prismaQuerySchema.optional(),
  })
  .merge(prismaQuerySchema);

export const userSearchRequestSchema = z.object({
  query: querySchema.optional(),
  limit: intSchema.optional(),
  page: intSchema.optional(),
  format: z.enum(['json', 'csv']).optional(),
  sortBy: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
  include: z.array(z.string()).optional(),
  textSearch: z.string().optional(),
  displayDeleted: booleanSchema,
  displayActive: booleanSchema,
  followupType: followupTypeSchema.optional(),
});

export const searchUsersResponseSchema = z.object({
  status: z.enum([STATUS.OK, STATUS.ERROR]),
  total: z.number(),
  items: z.array(userViewSchema),
});

export type User = z.infer<typeof userSchema>;
export type UserView = z.infer<typeof userViewSchema>;
export type License = z.infer<typeof licenseSchema>;
export type CoachData = z.infer<typeof coachSchema>;
export type SearchUsersRequest = z.infer<typeof userSearchRequestSchema>;
export type SearchUsersResponse = z.infer<typeof searchUsersResponseSchema>;

const usersEndpoint = `user/search`;

export interface GetUsersResponse {
  items: User[];
  total: number;
}

export interface GetUserResponse {
  user: UserView;
  onboarding: object;
}

export const getUsers = async (body?: SearchUsersParams) => {
  const json = body ?? {};
  const { items, total } = await api.post(usersEndpoint, { json }).json<GetUsersResponse>();
  return { items, total };
};

export const searchUsers = async (body?: SearchUsersRequest) => {
  return api
    .post(`user/v2/search`, { json: queryResponseValidator(userSearchRequestSchema)(body) ?? {} })
    .json()
    .then(queryResponseValidator<SearchUsersResponse>(searchUsersResponseSchema));
};

export const getUsersAsCsv = async (body?: SearchUsersParams) => {
  const json = body ?? {};
  const csv = await api.post(`user/v2/search?format=csv`, { json }).text();
  return csv;
};

export const getUser = async (userId: string) => {
  const response = await api.get(`user/v2/${userId}`).json<GetUserResponse>();
  return response.user;
};

export const deleteUser = async (userId: string) => {
  const response = await api.delete(`user/${userId}`).json<GetUserResponse>();
  return response.user;
};

export const setTrialPeriod = async (body?: TrialPeriodParams) => {
  const json = body ?? {};
  const response = await api.post('user/trial', { json }).json();
  return response;
};

export const suspendAccess = async (userId: string) => {
  const response = await api.put(`user/suspend-access/${userId}`).json();
  return response;
};
