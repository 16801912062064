import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from './Dashboard';
import { Center, Image } from '@mantine/core';
import AgaveImage from 'assets/agave_bo_home.png';

const HomeView = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? (
    <Dashboard />
  ) : (
    <Center sx={{ background: '#f2f1ef' }}>
      <Image width={590} src={AgaveImage} />
    </Center>
  );
};

export default HomeView;
