import { createStyles, getSize } from '@mantine/core';

export default createStyles((theme, _params, { size }) => ({
  infiniteList: {
    minHeight: '200px',
    minWidth: '100px',
  },

  item: {
    alignItems: 'center',
  },

  selected: {
    backgroundColor: theme.colors[theme.primaryColor][0],
  },

  nothingFound: {
    boxSizing: 'border-box',
    color: theme.colors.gray[6],
    paddingTop: `calc(${getSize({ size, sizes: theme.spacing })} / 2)`,
    paddingBottom: `calc(${getSize({ size, sizes: theme.spacing })} / 2)`,
    textAlign: 'center',
  },
}));
