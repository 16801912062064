import React from 'react';
import AppHeader from 'components/Layout/AppHeader';
import { Box, Flex } from '@mantine/core';
import { useAppHeaderStyles } from 'components/Layout/layoutStyles';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const { classes } = useAppHeaderStyles();
  return (
    <Flex direction="column">
      <AppHeader />
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>{children}</Box>
      </div>
    </Flex>
  );
};

export default AppLayout;
