import React from 'react';
import AppLayout from 'components/Layout/AppLayout';
import { QueryClientProvider } from '@tanstack/react-query';
import UserProvider from 'context/UserProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserStore } from 'store/userStore';
import AuthenticatedRoutes from 'routes/AuthenticatedRoutes';
import queryClient from 'helpers/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const AuthenticatedApp = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const fetchUserData = useUserStore((state) => state.fetchUserData);

  React.useEffect(() => {
    fetchUserData(getAccessTokenSilently, user!);
  }, [fetchUserData, getAccessTokenSilently, user]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <UserProvider>
        <AppLayout>
          <AuthenticatedRoutes />
        </AppLayout>
      </UserProvider>
    </QueryClientProvider>
  );
};

export default AuthenticatedApp;
