import React from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'store/userStore';
import { useAuth0 } from '@auth0/auth0-react';

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation('core');
  const { userData, fetchError } = useUserStore();
  const { logout } = useAuth0();

  React.useEffect(() => {
    if (fetchError) {
      showNotification({
        title: t('core:error'),
        message: fetchError,
        color: 'red',
      });
      logout();
    }
  }, [fetchError, t, logout]);

  return (
    <React.Suspense fallback={<LoadingOverlay visible />}>
      <Box>{userData?.me?.userId ? children : <LoadingOverlay visible={true} />}</Box>
    </React.Suspense>
  );
};

export default UserProvider;
