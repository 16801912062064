import React, { useCallback, useRef, useState, useEffect } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List, { ListRowProps } from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import c from 'classnames';
import { IndexRange, Index } from 'react-virtualized';

import useStyles from './InfiniteList.styles';
import { identity } from 'helpers/miscUtils';
import { LoadingOverlay } from '@mantine/core';

export type GetItemQuery<TValue = string> = (
  startIndex: number,
  endIndex: number,
  searchTerm?: string,
) => Promise<{ items: TValue[]; total: number }>;

export type InfiniteListItem<TValue = string> = {
  label: string;
  value: TValue;
  idx?: number;
};
export type InfiniteListItemProps<TValue = string> = ListRowProps & {
  item: TValue;
  isSelected: boolean;
};
export type InfiniteListRowRenderer<TValue = string> = (
  props: InfiniteListItemProps<TValue>,
) => React.ReactNode;

export type InfiniteListProps<TValue = string> = {
  selectedItems?: Array<string>;
  onSelect?: (item: InfiniteListItem<TValue>) => void;
  itemToLabel?: (item: TValue) => string;
  itemToValue?: (item: TValue) => string;
  getItemsQuery: GetItemQuery<TValue>;
  rowRenderer?: InfiniteListRowRenderer<TValue>;
  minimumBatchSize?: number;
  threshold?: number;
  rowHeight?: number;
  filterStr?: string;
  nothingFound?: React.ReactNode;
};

export function InfiniteList<TValue = string>({
  onSelect,
  getItemsQuery,
  selectedItems = [],
  itemToLabel = identity,
  rowRenderer,
  filterStr,
  minimumBatchSize = 30,
  threshold = 5,
  rowHeight = 32,
  nothingFound,
  ...props
}: InfiniteListProps<TValue>) {
  const { classes } = useStyles();
  const itemToValue = props.itemToValue ?? itemToLabel;
  const listRef = useRef<InfiniteLoader | null>(null);
  const itemsRef = useRef<Record<number, TValue>>({});
  const isLoadingRef = useRef(false);
  const [totalItems, setTotalItems] = useState(-1);

  useEffect(() => {
    listRef.current?.resetLoadMoreRowsCache(true);
  }, [filterStr]);

  const loadMoreQuery = useCallback(
    ({ startIndex, stopIndex }: IndexRange) => {
      return isLoadingRef.current
        ? Promise.resolve(true)
        : new Promise((resolve) => {
            isLoadingRef.current = true;
            void getItemsQuery(startIndex, stopIndex + 1, filterStr)
              .then(({ items, total }) => {
                setTotalItems(total);

                for (let i = 0; i < items.length; i++) {
                  itemsRef.current[i + Number(startIndex)] = items[i];
                }

                resolve(true);
              })
              .catch((ex) => {
                console.error(
                  `InfiniteList: failed to retrieve items for ${startIndex}-${stopIndex} range.`,
                );
              })
              .finally(() => {
                isLoadingRef.current = false;
              });
          });
    },
    [getItemsQuery, filterStr],
  );

  const isRowLoaded = useCallback(({ index }: Index) => {
    return !!itemsRef.current[index];
  }, []);

  const defaultRowRenderer = useCallback(
    ({ index, key, style, item, isSelected }: InfiniteListItemProps<TValue>) => {
      const isLoaded = !!item;

      return (
        <div
          style={style}
          className={c(classes.item, isSelected && classes.selected)}
          key={key}
          onClick={
            isLoaded
              ? () => onSelect?.({ label: itemToLabel(item), value: item, idx: index })
              : undefined
          }>
          {isLoaded ? itemToLabel(item) : null}
        </div>
      );
    },
    [classes.item, classes.selected, itemToLabel, onSelect],
  );

  const handleRowRender = useCallback(
    // ListRowProps contain only index, which is inconvenient, so we are augumenting them with an actual item
    (props: ListRowProps) => {
      const item = itemsRef.current[props.index];
      const isSelected =
        !!selectedItems.find((selectedItem) => itemToValue(item) === selectedItem) ?? false;
      return (rowRenderer ?? defaultRowRenderer)({ ...props, item, isSelected });
    },
    [defaultRowRenderer, itemToValue, rowRenderer, selectedItems],
  );

  // kick off initial load on mount
  useEffect(() => {
    void loadMoreQuery({ startIndex: 0, stopIndex: minimumBatchSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContents = () => {
    if (totalItems === 0) {
      return <div className={classes.nothingFound}>{nothingFound}</div>;
    }
    if (totalItems < 0) {
      return <LoadingOverlay visible />;
    }

    return (
      <InfiniteLoader
        ref={listRef}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreQuery}
        rowCount={totalItems}
        minimumBatchSize={minimumBatchSize}
        threshold={threshold}>
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={registerChild}
                width={width}
                height={height}
                onRowsRendered={onRowsRendered}
                rowCount={totalItems}
                rowRenderer={handleRowRender}
                rowHeight={rowHeight}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  };

  return <div className={classes.infiniteList}>{renderContents()}</div>;
}
