import React from 'react';
import { Avatar } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from 'assets/logo_icon_only.svg';

const AppLogo = () => {
  const { isAuthenticated } = useAuth0();
  const to = isAuthenticated ? '/' : '/login';
  return (
    <Link to={to}>
      <Avatar src={Logo} alt="logo" />
    </Link>
  );
};

export default AppLogo;
