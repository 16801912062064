import { Box, Title, Text } from '@mantine/core';
import React from 'react';
import { useSystemStyles } from 'views/System/System.styles';

const PageNotFound = () => {
  const { classes } = useSystemStyles();
  return (
    <Box className={classes.systemPage}>
      <Title className={classes.title}>404</Title>
      <Title className={classes.subTitle} mb={16}>
        Not Found
      </Title>
      <Text mb={8}>The resource requested could not found</Text>
      <Text variant={'link'} component="a" href="/chat">
        Go Back Home
      </Text>
    </Box>
  );
};

export default PageNotFound;
