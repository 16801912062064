import { createStyles } from '@mantine/core';

export const useAppHeaderStyles = createStyles((theme) => {
  return {
    header: {
      background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      borderBottom: theme.other.elements.appBorder,
      zIndex: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 32,
    },
    contentWrapper: {
      height: '100vh',
      overflow: 'auto',
    },
    content: {
      marginTop: theme.other.appOffsetHeight,
      padding: theme.spacing.sm,
    },
    nav: {
      minWidth: 0,
      minHeight: 0,
      alignItems: 'center',
      height: '20px',
    },
    navLink: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      ':hover': {
        color: theme.colors.teal[6],
      },
      '&.active': {
        color: theme.colors.teal[6],
        fontWeight: 600,
      },
    },
  };
});
