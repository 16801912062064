import { Avatar, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { forwardRef } from 'react';
import Avvvatars from 'avvvatars-react';
import { User } from 'types';

interface UserAvatarProps extends UnstyledButtonProps {
  user: User;
  size?: number;
}

export const UserAvatar = forwardRef<HTMLButtonElement, UserAvatarProps>(
  ({ user, size = 40, ...props }: UserAvatarProps, ref) => {
    return (
      <UnstyledButton ref={ref} {...props}>
        {user?.avatarUrl ? (
          <Avatar radius="50%" src={user.avatarUrl} size={size} />
        ) : user?.firstName ? (
          <Avvvatars value={user?.firstName} size={size} />
        ) : (
          <Avatar color="teal" radius="50%" size={size} />
        )}
      </UnstyledButton>
    );
  },
);
