import api from 'api/client';
import { User, UserAnswer, UserData } from 'types';
import { License, UserView } from './users';

const userEndpoint = `user/me`;
const userByIdEndpoint = `user`;

export const getUserData = async () => {
  return api(userEndpoint).json<UserData>();
};

export interface UpdateUserFormValues {
  nickname?: string;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  calendarUrl?: string;
  description?: string;
  introCallUrl?: string;
  oneLiner?: string;
  richTextDescription?: string;
  tags?: string;
  timezone?: string;
  weekendWelcomeVideoUrl?: string;
  license?: License[];
}

export const updateUser = async (body: UpdateUserFormValues, userId?: string) => {
  const endpoint = userId ? `user/${userId}` : userEndpoint;
  return api.put(endpoint, { json: body, timeout: 60 * 1000 }).json<UserData>();
};

export type GetUserByIdResponse = {
  user: UserData;
  onboarding?: UserAnswer[];
};
export const getUserById = async (userId: string) => {
  return api(`${userByIdEndpoint}/v2/${userId}`).json<GetUserByIdResponse>();
};

export const toggleRotation = async (userId: string) => {
  return api.put(`user/${userId}/toggle-rotation`).json<User>();
};

export const toggleFollowRequirement = async (userId: string) =>
  api.put(`user/${userId}/toggle-followup-requirement`).json<UserView>();
