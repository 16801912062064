import { CSSObject, MantineTheme } from '@mantine/core';
import { MantineThemeOverride } from '@mantine/styles/lib/theme/types';

const fontFamily =
  '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;';

export const getGlobalStyle = (theme: MantineTheme): CSSObject | CSSObject[] => ({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },

  body: {
    background:
      theme.colorScheme === 'dark'
        ? `${theme.colors.dark}`
        : `${theme.other.colors.appLightBackgroundColor}!important`,
    fontFamily,
    overflowY: 'hidden',
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
});

export const appTheme: MantineThemeOverride = {
  // Override any other properties from default theme
  fontFamily,
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.25rem',
  },
  headings: {
    fontFamily,
  },
  primaryColor: 'teal',
  primaryShade: { light: 4, dark: 8 },
  other: {
    colors: {
      appLightBackgroundColor: 'rgb(248, 249, 252)',
    },
    elements: {
      appBorder: '1px solid rgba(72, 94, 144, 0.16)',
    },
    appOffsetHeight: 69,
  },
  loader: 'bars',
};
