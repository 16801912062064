import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from 'context/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import {
  MantineProvider,
  Global,
  createEmotionCache,
  ColorSchemeProvider,
  ColorScheme,
} from '@mantine/core';
import { appTheme, getGlobalStyle } from 'styles/theme';
import { Notifications } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import './i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const emotionCache = createEmotionCache({ key: 'agave' });

const Main = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });

  const toggleColorScheme = () =>
    setColorScheme((current) => (current === 'dark' ? 'light' : 'dark'));

  return (
    <React.StrictMode>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          emotionCache={emotionCache}
          theme={{ ...appTheme, colorScheme }}
          withNormalizeCSS
          withGlobalStyles>
          <Global styles={(theme) => getGlobalStyle(theme)} />
          <Notifications position="top-center" />
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </MantineProvider>
      </ColorSchemeProvider>
    </React.StrictMode>
  );
};
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
