import { z } from 'zod';

export enum STATUS {
  OK = 'OK',
  ERROR = 'error',
}

export const paginatedRequestSchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  sortBy: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
});

export type PaginatedRequest<T = any> = z.infer<typeof paginatedRequestSchema> & {
  sortBy?: keyof T;
};

export type PaginatedResponse<T> = {
  status: STATUS;
  total: number;
  items: T[];
};
