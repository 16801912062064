import { isNumber, isEmpty, isNaN } from 'lodash';

export const noop = (...args: any[]) => {};

export const safeParseJSON = (str: string) => {
  if (typeof str !== 'string') return str;

  try {
    return JSON.parse(str);
  } catch (e) {
    // console.error(e);
    return str;
  }
};

export const identity = (value: any) => value;

export const getFirstObjValue = (obj: any) => {
  if (typeof obj !== 'object') return obj;
  const key = Object.keys(obj)[0];
  return obj[key];
};

export const readAsText = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result as string);
    };
    fr.onerror = reject;
    fr.readAsText(file);
  });
};

export const isNumeric = (value: any): boolean => {
  return isNumber(value) || (!isEmpty(value) && !isNaN(parseFloat(value)));
};
