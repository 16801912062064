import React from 'react';
import { useMe } from 'store/userStore';
import { Navigate } from 'react-router-dom';
import { Permission } from 'api/users';
import { can } from 'helpers/userUtils';

const Dashboard = () => {
  const me = useMe();

  if (can(me, Permission.MODERATION_READ)) {
    return <Navigate to={'/users?sortBy=createdAt&order=desc'} />;
  }

  if (can(me, Permission.COACHING_READ)) {
    return <Navigate to={'/chat'} />;
  }

  return <Navigate to={'/settings/user/profile'} />;
};

export default Dashboard;
