import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingOverlay } from '@mantine/core';
import withPermissions, { WithPermissions } from 'hocs/withPermissions';

interface ProtectedRouteProps extends WithPermissions {
  component: ComponentType;
}

const ProtectedRoute = ({ component, ...props }: ProtectedRouteProps) => {
  const Component = withPermissions(
    withAuthenticationRequired(component, {
      onRedirecting: () => <LoadingOverlay visible />,
    }),
  );

  return <Component {...props} />;
};

export default ProtectedRoute;
