import { createStyles } from '@mantine/core';

export const useSystemStyles = createStyles((theme) => {
  return {
    systemPage: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 120,
    },
    subTitle: {
      fontSize: 30,
    },
  };
});
