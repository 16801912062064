import { Box, Title, Text } from '@mantine/core';
import React from 'react';
import { useSystemStyles } from 'views/System/System.styles';

const PageForbidden = () => {
  const { classes } = useSystemStyles();
  return (
    <Box className={classes.systemPage}>
      <Title className={classes.title}>403</Title>
      <Title className={classes.subTitle} mb={16}>
        You are not authorized to view this page
      </Title>
      <Text mb={8}>The resource requested cannot be accessed</Text>
      <Text variant={'link'} component="a" href="/chat">
        Go Back Home
      </Text>
    </Box>
  );
};

export default PageForbidden;
