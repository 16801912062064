import { createStyles } from '@mantine/core';

export default createStyles((theme) => ({
  itemsWrapper: {
    width: '100%',
  },

  item: {
    margin: '4px',
    padding: '4px 8px',
    borderRadius: '4px',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },

  isItemSelected: {
    backgroundColor: theme.colors.teal[0],

    ':hover': {
      backgroundColor: theme.colors.teal[1],
    },
  },
}));
