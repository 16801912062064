import { UserView } from 'api/users';
import dayjs from 'dayjs';
import { Role, SortOrder, SubscriptionStatus, User, UsersFilter } from 'types';

export const filterKeys = {
  displayDeleted: 'displayDeleted',
  coachId: 'coachId',
  subscriptionStatus: 'subscriptionStatus',
  role: 'role',
  textSearch: 'textSearch',
  sortBy: 'sortBy',
  order: 'order',
};

export const getQuery = (searchParams: URLSearchParams) => {
  const query: UsersFilter = {};
  if (searchParams.get(filterKeys.displayDeleted) === 'true') {
    query.displayDeleted = true;
  }
  if (searchParams.get(filterKeys.coachId)) {
    query.coachId = searchParams.get(filterKeys.coachId);
  }
  if (searchParams.get(filterKeys.subscriptionStatus)) {
    query.subscriptionStatus = searchParams.get(
      filterKeys.subscriptionStatus,
    ) as SubscriptionStatus;
  }
  if (searchParams.get(filterKeys.role)) {
    const value = searchParams.get(filterKeys.role);
    query.isCoach = value === Role.Coach;
  }
  if (searchParams.get(filterKeys.textSearch)) {
    query.textSearch = searchParams.get(filterKeys.textSearch) as string;
  }
  if (searchParams.get(filterKeys.sortBy)) {
    const sortByValue = searchParams.get(filterKeys.sortBy);
    query.sortBy = sortByValue as keyof User;
  }
  if (searchParams.get(filterKeys.order)) {
    const orderValue = searchParams.get(filterKeys.order);
    query.order = orderValue === 'asc' ? SortOrder.asc : SortOrder.desc;
  }
  return query;
};

export const getAccessEndDate = (user: User): Date | null => {
  switch (user.subscriptionStatus) {
    case SubscriptionStatus.CANCELED:
      return dayjs(user.subscriptionLastPaymentDate).add(1, 'month').add(5, 'days').toDate();
    case SubscriptionStatus.TRIAL:
      return dayjs(user.trialEndDate).toDate();
    default:
      // for all other statuses it is indefinite
      return null;
  }
};

export const hasAccessTimeLeft = (user: UserView | User): boolean => {
  const accessEndDate = getAccessEndDate(user);
  return accessEndDate === null || dayjs().isBefore(dayjs(accessEndDate));
};

export const getUserDisplayName = (user: UserView | User) =>
  (user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.nickname) ??
  user.email;
